"use client";
import type React from "react";

import { Button, Modal } from "@/components";
import { useAuth } from "@/context/authContext";
import { reachOutMailHandler } from "@/utils/mail";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";

export default function AccessDeniedModal() {
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();
  const {
    isAccessDeniedModalOpen,
    closeAccessDeniedModal,
    isOnCorrectNetwork,
    isUserInvestor,
    isConnected,
  } = useAuth();

  const getContent = () => {
    if (!isConnected) {
      return (
        <p>
          To gain access to these features, please connect your wallet first.
        </p>
      );
    }

    if (!isOnCorrectNetwork) {
      return (
        <>
          <p>You are connected to the wrong network.</p>
          <p>Please change to the Mainnet network.</p>
        </>
      );
    }

    if (!isUserInvestor) {
      return (
        <>
          <p>Your account is not approved.</p>
          <p>Please reach out to our team to get access.</p>
        </>
      );
    }
  };

  const getButtonProps = () => {
    if (!isConnected) {
      return {
        onClick: openConnectModal,
        text: "Connect Wallet",
      };
    }

    if (!isOnCorrectNetwork) {
      return {
        onClick: openChainModal,
        text: "Switch Network",
      };
    }

    return {
      onClick: reachOutMailHandler,
      text: "Reach Out",
    };
  };

  const { onClick, text } = getButtonProps();

  return (
    <Modal
      isOpen={isAccessDeniedModalOpen}
      hasCloseButton={true}
      title="Access Denied"
      isTitleCentered={true}
      onClose={closeAccessDeniedModal}
    >
      <div className="font-sans text-base flex flex-col items-center px-10">
        {getContent()}
        <Button className="mt-5" variant="primary" onClick={onClick}>
          {text}
        </Button>
      </div>
    </Modal>
  );
}
